// 商品评论相关接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-01-21
 */
//商品评论分页
export function getCommentsList(parameter){
    return axios({
        url: '/evaluate/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryPrarm
    })
}
// 商品评论详情
export function commentsDetail(parameter){
    return axios({
        url: '/evaluate/detail',
        method: 'post',
        params:parameter,
    })
}
// //商品分类新增
export function commentsDelete(parameter){
    return axios({
        url: '/evaluate/delete',
        method: 'post',
        data: parameter
    })
}