<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :confirmLoading="confirmLoading"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col>
                        <!-- 第1行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="商品名称">
                                    <span>{{details.goodsName}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="用户昵称">
                                    <span>{{details.customerName}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第2行详情 -->
                        <a-row>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="创建时间">
                                    <span>{{details.createTime}}</span>
                                </a-form-item>
                            </a-col>
                            <a-col :md="12" :sm="24">
                                <a-form-item  label="评分">
                                    <span>{{details.star}} 分</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第3行详情 -->
                        <a-row>
                            <a-col :md="24" :sm="24">
                                <a-form-item  label="评价内容">
                                    <span>{{details.content}}</span>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第4行详情 -->
                        <a-row v-show="goodsImagesList.length > 0">
                            <a-col :md="24" :sm="24">
                                <a-form-item   label="商品图片">
                                    <img 
                                        v-for="(item, index) in goodsImagesList"
                                        style="height:25%; padding:3px;margin:3px;border:0.5px solid #ccc"
                                        :key="index"
                                        :src="item"
                                        @click="showGoodsImg(item)"
                                    >
                                    <!-- 点开大图 -->
                                    <a-modal 
                                        :visible="showGoodsImage"
                                        :footer="null" 
                                        @cancel="handleCancel_1"
                                    >
                                        <img style="width: 100%;height:100%;padding:10px;" :src="goodsImage" />
                                    </a-modal>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <!-- 第5行详情 -->
                        <a-row v-show="imagesList.length > 0">
                            <a-col :md="24" :sm="24">
                                <a-form-item   label="评价图片">
                                    <img 
                                        v-for="(item, index) in imagesList"
                                        style="height:25%; padding:3px;margin:3px;border:0.5px solid #ccc"
                                        :key="index"
                                        :src="item"
                                        @click="showImg(item)"
                                    >
                                    <!-- 点开大图 -->
                                    <a-modal 
                                        :visible="showImage"
                                        :footer="null" 
                                        @cancel="handleCancel_2"
                                    >
                                        <img style="width: 100%;height:100%;padding:10px;" :src="bigImg" />
                                    </a-modal>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { commentsDetail } from '@/api/modular/mallLiving/commodity/commodityComments'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            showGoodsImage:false,
            showImage:false,
            id:'',
            details:{},
            //图片相关
            goodsImagesList:[],//商品图片列表
            imagesList:[],//评价图片
            goodsImage:'',//商品图片大图
            bigImg:''//评价图片大图
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                commentsDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                        // 如果商品图片不为空，将轮播图字符串分割成数组
                        if(this.details.goodsImage){
                            this.goodsImagesList = this.details.goodsImage.split(',')
                        }else{
                            this.goodsImagesList = []
                        }
                        // 如果评价图片不为空，将轮播图字符串分割成数组
                        if(this.details.images){
                            this.imagesList = this.details.images.split(',')
                        }else{
                            this.imagesList = []
                        }
                    }
                })
            }, 200)
            
        },
        // 展示商品大图
        showGoodsImg(item){
            this.showGoodsImage = true
            this.goodsImage = item
        },
        // 展示评论大图
        showImg(item){
            this.showImage = true
            this.bigImg = item
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.details = {}
        },
        // 关闭展示商品大图弹框
        handleCancel_1(){
            this.showGoodsImage = false
        },
        // 关闭展示评论大图弹框
        handleCancel_2(){
            this.showImage = false
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        .con{
            width:30%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding-left:7px;
            font-size:15px;
        }
    }
    img{
        width:100px;
        height: 50px;
    }
</style>